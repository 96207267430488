/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/efdecffa81c0d20a-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/57ecc62f9efc0468-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/f44a8869f4aea6e8-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/9dd139e8926a2e7e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/4066a109b83d587e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/f08a2e5a4223f992-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/d73d4c4ded2c5368-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/e906e20e2a4d9fb8-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/7193f4824078beb7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/49a6879b3a7cb9dd-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/c2b3030ec54aa82a-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/6c7fefe4133d3d69-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/4a8e144540cb78f0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/8a95971cc95c0b89-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7f18ed7cdbfaf733-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/000325604fc3139a-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cf6f59ef74797778-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/00eecbc6c81bba79-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f29c91ab0b6ba852-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/401536ba6ac57b4b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/9b0ae27abebf40e8-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c4c946529488ae08-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3a3501e984130d11-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/1f762cf117c5b3ec-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/9e84385130e79dc9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/4772d89fe3622362-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/38a8767813a55726-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b7ab1bb36ff4f0e1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7f9085f785625122-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ccfaa8f4f2bbe8c6-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/dab5b50288580af2-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f9c3bd5c25a2c22c-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fb40faefeefda72d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0e5eaf913b22d426-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/90cb4c83bb692c13-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/81301d94473df68f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/cc4c5b843e733331-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/b04064dcf54727fb-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/847d6d299f4f7ffd-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/0106b814569b6baf-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/8a46311dd602cbdd-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/9860e21d8a634718-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/e555ceb596f025e5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/693454feef6f1b7a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_c49fea';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/5a7daec4380d6e82-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Montserrat_Alternates_Fallback_c49fea';src: local("Arial");ascent-override: 83.96%;descent-override: 21.77%;line-gap-override: 0.00%;size-adjust: 115.30%
}.__className_c49fea {font-family: '__Montserrat_Alternates_c49fea', '__Montserrat_Alternates_Fallback_c49fea';font-style: normal
}.__variable_c49fea {--font-montserrat: '__Montserrat_Alternates_c49fea', '__Montserrat_Alternates_Fallback_c49fea'
}

/* greek */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/10c4eb715c61a3e6-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5154c5c28c3353bb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0acf7bf726cd1ef0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* greek */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/10c4eb715c61a3e6-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/5154c5c28c3353bb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/0acf7bf726cd1ef0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* greek */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/10c4eb715c61a3e6-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/5154c5c28c3353bb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0acf7bf726cd1ef0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* greek */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/10c4eb715c61a3e6-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/5154c5c28c3353bb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0acf7bf726cd1ef0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* greek */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/10c4eb715c61a3e6-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/5154c5c28c3353bb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Syne_90b813';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/0acf7bf726cd1ef0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Syne_Fallback_90b813';src: local("Arial");ascent-override: 93.93%;descent-override: 27.93%;line-gap-override: 0.00%;size-adjust: 98.47%
}.__className_90b813 {font-family: '__Syne_90b813', '__Syne_Fallback_90b813';font-style: normal
}.__variable_90b813 {--font-syne: '__Syne_90b813', '__Syne_Fallback_90b813'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eed6db14ac3b93a0-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* vietnamese */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/65053818c3abcf97-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/034043092db1e233-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2b3f1035ed87a788-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/eed6db14ac3b93a0-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* vietnamese */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/65053818c3abcf97-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/034043092db1e233-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/2b3f1035ed87a788-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/eed6db14ac3b93a0-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* vietnamese */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/65053818c3abcf97-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/034043092db1e233-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2b3f1035ed87a788-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/eed6db14ac3b93a0-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* vietnamese */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/65053818c3abcf97-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/034043092db1e233-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/2b3f1035ed87a788-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/eed6db14ac3b93a0-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* vietnamese */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/65053818c3abcf97-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/034043092db1e233-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Plus_Jakarta_Sans_dd7089';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/2b3f1035ed87a788-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Plus_Jakarta_Sans_Fallback_dd7089';src: local("Arial");ascent-override: 98.88%;descent-override: 21.15%;line-gap-override: 0.00%;size-adjust: 104.98%
}.__className_dd7089 {font-family: '__Plus_Jakarta_Sans_dd7089', '__Plus_Jakarta_Sans_Fallback_dd7089';font-style: normal
}.__variable_dd7089 {--font-plus_jakarta_sans: '__Plus_Jakarta_Sans_dd7089', '__Plus_Jakarta_Sans_Fallback_dd7089'
}

/* latin-ext */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe0777f1195381cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f10b8e9d91f3edcb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8888a3826f4a3af4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c3bc380753a8436c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a1386beebedccca4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b957ea75a84b6ea7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/5fb25f343c7550ca-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/7db6c35d839a711c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_2382d5';src: local("Arial");ascent-override: 93.62%;descent-override: 31.21%;line-gap-override: 8.92%;size-adjust: 112.16%
}.__className_2382d5 {font-family: '__Poppins_2382d5', '__Poppins_Fallback_2382d5';font-style: normal
}.__variable_2382d5 {--font-poppins: '__Poppins_2382d5', '__Poppins_Fallback_2382d5'
}

